var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.member
    ? _c(
        "div",
        {
          staticClass:
            "teams-box teams-box-custom primary-gradient-bg member-header",
        },
        [
          _c(
            "div",
            { staticClass: "gx-profile-banner gx-blue-cyan-gradient" },
            [
              _c("div", { staticClass: "gx-profile-container" }, [
                _c("div", { staticClass: "gx-profile-banner-top" }, [
                  _c("div", { staticClass: "gx-profile-banner-top-left" }, [
                    _c(
                      "div",
                      { staticClass: "gx-profile-banner-avatar-info" },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.member.first_name) +
                                " " +
                                _vm._s(_vm.member.last_name) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._m(0),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "gx-profile-banner-top-right" }),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      { staticClass: "gx-mb-0 gx-fs-lg member-header--has-payment" },
      [
        _c("img", {
          attrs: { src: require("@/assets/custom-icons/shield.svg") },
        }),
        _c("span", [_vm._v("Payment Source Verified")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }